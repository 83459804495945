import React from 'react';
import SectionContact from './SectionContact';
// import SectionGetInTouch from './SectionGetInTouch'


function FooterContact(host) {
    return(
        <section className="content-block" id="contact">
            {/* <SectionGetInTouch host={host}/> */}
            <SectionContact/>
        </section>
    )
}

export default FooterContact
