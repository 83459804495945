import React from 'react';
import { render } from 'react-snapshot';
import App from './App';


const rootEl = document.getElementById('root');

// const pixelId = '932469270419829';


render(<App />, rootEl)

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default
    render(<NextApp />, rootEl)
  })
}