import React from 'react';

function SectionWhoWeAre() {
    return(
        <section className="content-block who-we-are" id="whoWeAre">
            <div className="cl-full">
                <h2>Who We Are</h2>
            </div>
            <div className="grid">
                <div className="square square--lightgray gc-xs-1 gr-xs-1 gc-md-1 gr-md-1 gc-lg-1 gr-lg-2">
                    <span className="square_content"><b>14</b>years</span>
                </div>
                <div className="square square--lightgray gc-lg-6 gr-lg-1 square__floating">

                </div>
                <div className="square square--red gc-xs-1-3 gr-xs-2-4 gc-md-2-4 gr-md-2-4 gc-lg-2-4 gr-lg-3-5">
                    <span className="square_content"><b>300+</b>satisfied customers</span>
                </div>
                <div className="square square--gray gc-xs-1 gr-xs-4 gc-md-1 gr-md-4 gc-lg-4 gr-lg-3">
                    <span className="square_content"><b>UA</b>Lviv</span>
                </div>
                <div className="square square--gray gc-xs-2 gr-xs-5 gc-md-2 gr-md-5 gc-lg-5 gr-lg-4">
                    <span className="square_content"><b>DE</b>Munich</span>
                </div>
                <div className="square square--lightgray gc-xs-1 gr-xs-6 gc-md-4 gr-md-4 gc-lg-6 gr-lg-3">
                    <span className="square_content"><b>20</b>team members</span>
                </div>
            </div>
        </section>
    )
}

export default SectionWhoWeAre