import React, { Component } from 'react';
import Background from './commons/Background';
import HomePage from './containers/home';
import SoftwareDevelopment from './containers/software-development';
import Design from './containers/design';
import QualityAssurance from './containers/quality-assurance';
import Blog from './containers/blog';
import Article from './containers/article';
import NoMatch from './containers/no-match';
import Portfolio from './containers/portfolio';
import PortfolioArticle from './containers/portfolio-item';
// import { Switch, Route } from 'react-router-dom';
// import './styles/style.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
// import Helmet from 'react-helmet'
import ScrollToTop from './commons/ScrollToTop';
import './styles/style.scss';

const routes = [
    {
        title: 'Home',
        path: '/',
        component: HomePage,
        exact: true
    }, {
        title: 'software-development',
        path: '/software-development',
        component: SoftwareDevelopment,
        exact: true
    }, {
        title: 'Design',
        path: '/design',
        component: Design,
        exact: true
    }, {
        title: 'Quality Assurance',
        path: '/quality-assurance',
        component: QualityAssurance,
        exact: true
    }, {
        title: 'Blog',
        path: '/blog',
        component: Blog,
        exact: true
    }, {
        title: 'Article',
        path: '/blog/:link',
        component: Article,
        exact: true
    },
    {
        title: 'Portfolio',
        path: '/portfolio',
        component: Portfolio,
        exact: true
    },
    {
        title: 'PortfolioArticle',
        path: '/portfolio/:link',
        component: PortfolioArticle,
        exact: true
    }
]

class App extends Component {

    render() {
        return (
            <Router>
                <div className={"wrapper-background loaded"}>
                    <div className="preload-screen">
                        <img className="logo" src="images/logo.svg" alt="artfulbits-logo"/>
                    </div>
                <Background/>
                <section className="wrapper">
                    <div className="content">

                {/* <Helmet 
                    titleTemplate={`%s`}
                    meta={[
                        {property: "og:title", content: "bla bla"}
                    ] }
                /> */}
                <ScrollToTop>
                    <Switch>
                        {routes.map((route, i) => (
                        <Route key={i} {...route} />
                        ))}
                        <Route component={NoMatch} />
                    </Switch>
                </ScrollToTop>

                        </div>
                    </section>
                </div>
            </Router>
        );
    }
}

export default App;
