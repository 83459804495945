import React from 'react';

function SectionListBlock(props) {
    return(
        <section className="content-block how-we-do list-block" id="howWeDo">
            <div className="cl-full">
                <h2>{ListContent.title}</h2>
            </div>
            <div className="list-block__item_background">

            </div>
            {ListContent.items.map((item, index) =>
                <div className="clxs-2 clmd-4 cllg-4 list-block__item" key={index}>
                    <div className="list-block__item_number">
                        {'0' + (index + 1)}
                    </div>
                    <div className="list-block__item_content">
                        <h3>{item.title}</h3>
                        <p className="text">
                            {item.descript}
                        </p>
                    </div>
                </div>
            )}
        </section>
    )
}

export default SectionListBlock

const ListContent = {
    title: 'Why working with us?',
    items: [
        {
            title: 'Convenience',
            descript: 'Our time zone is 1 hour away from Munich and 7 hours away from New York. We speak English, German, Russian, Polish, and Ukrainian. We use up-to-date communication and management tools. It is convenient working with us.'
        },
        {
            title: 'Dedication',
            descript: 'We are small team of professionals. We do not chase many things at a time. We focus on what we do now, and we do it right. We are enthusiastic about every project, every customer, every partner. It is profitable working with us.'
        },
        {
            title: 'Reliability',
            descript: 'We have been in this field since 2005. There were rises and falls but we are still here. We may miss a deadline, but if that happen, we will put all our efforts to minimize consequences and get back on track. Our skills are proven by diplomas and international certifications. It is safe working with us.'
        },
        {
            title: 'Integrity',
            descript: 'We believe that communication is the key to success. For our customers we offer direct communication channel with our team and access to our internal tools so they can be aware about the progress. It is pleasure working with us.'
        }
    ]
};