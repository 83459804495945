import React, { Component } from 'react';
import Footer from './Footer'
import { Link } from 'react-scroll'
import { Link as LinkTo} from 'react-router-dom'

import Background from './Background'

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visibility: false,
            windowWidth: 0,
            windowHeight: 0,
            mainPage: this.props.mainPage
        };

        this.handleBtnNavClick = this.handleBtnNavClick.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener("resize", this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        let windowWidth = window.innerWidth,
            windowHeight = window.innerHeight;
        this.setState({ windowWidth, windowHeight });
    }

    handleBtnNavClick(){
        this.setState({
            visibility: !this.state.visibility
        })
    }

    getBtnClasses(){
        let btnClasses = "btn-nav ";
        btnClasses += this.state.visibility ? 'active' : '';
        return btnClasses;
    }

    getNavClasses() {
        const mainPage = this.state.mainPage;
        let navClasses = "nav cllg-5 ";
        navClasses += mainPage ? "offset--lg-1-left " : "offset--lg-4-left ";
        if(this.state.windowWidth < 990) {
            navClasses += this.state.visibility && 'shown';
        } else {
            navClasses += 'shown';
        }
        return navClasses;
    }

    getNavItems() {
        const mainPage = this.state.mainPage;
        if (mainPage) {
            return (
                <React.Fragment>
                    <div className="nav-item">
                        <Link onClick={this.handleBtnNavClick} activeClass="floating-nav__item-active" to="weDo" spy={true} smooth={true} offset={0} duration={500} delay={100}>
                            We Do
                        </Link>
                    </div>
                    <div className="nav-item">
                        <Link onClick={this.handleBtnNavClick} activeClass="floating-nav__item-active" to="whoWeAre" spy={true} smooth={true} offset={0} duration={500} delay={100}>
                            Who We Are
                        </Link>
                    </div>
                    <div className="nav-item">
                        <LinkTo to="/portfolio">
                            Portfolio
                        </LinkTo>
                    </div>
                    <div className="nav-item">
                        <Link onClick={this.handleBtnNavClick} activeClass="floating-nav__item-active" to="blog" spy={true} smooth={true} offset={0} duration={500} delay={100}>
                            Blog
                        </Link>
                    </div>
                    <div className="nav-item">
                        <Link onClick={this.handleBtnNavClick} activeClass="floating-nav__item-active" to="contact" spy={true} smooth={true} offset={0} duration={500} delay={100}>
                            Get in Touch
                        </Link>
                    </div>
                    {/* <div className="nav-item">
                        <a className="external-link" href="https://www.sharepoint-artfulbits.com" target="_blank">
                            SharePoint Products
                        </a>
                    </div> */}
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    <div className="nav-item">
                        <LinkTo to="/">
                            Home
                        </LinkTo>
                    </div>
                    <div className="nav-item">
                        <Link onClick={this.handleBtnNavClick} activeClass="floating-nav__item-active" to="contact" spy={true} smooth={true} offset={0} duration={500} delay={100}>
                            Get in Touch
                        </Link>
                    </div>
                </React.Fragment>
            )
        }
    }

    getLogo() {
        const mainPage = this.state.mainPage;
        if (mainPage) {
            return <img className="logo" src="/images/logo.svg" alt="artfulbits-logo"/>
        } else {
            return (
                <LinkTo to="/">
                    <img className="logo" src="/images/logo.svg" alt="artfulbits-logo"/>
                </LinkTo>
            )
        }
    }

    render() {
        return(
            <header className="clxs-2 clmd-4 cllg-6 content-block">
                {this.getLogo()}
                <button className={this.getBtnClasses()} onClick={this.handleBtnNavClick}>
                    <span className="btn-nav__line"> </span>
                    <span className="btn-nav__line"> </span>
                    <span className="btn-nav__line"> </span>
                </button>
                <nav className={this.getNavClasses()}>
                    {this.getNavItems()}
                    <Background/>
                    <Footer/>
                </nav>
            </header>
        )
    }

}

export default Header
