import React from 'react';
import { Link } from 'react-scroll'

function FloatingNav() {
    return (
        <section className="floating-nav">
            <ul className="text">
                <li>
                    <Link activeClass="floating-nav__item-active" to="home" spy={true} smooth={true} offset={-200} duration={500} delay={100}>
                        00
                    </Link>
                </li>
                <li>
                    <Link activeClass="floating-nav__item-active" to="weDo" spy={true} smooth={true} offset={0} duration={500} delay={100}>
                        01
                    </Link>
                </li>
                <li>
                    <Link activeClass="floating-nav__item-active" to="howWeDo" spy={true} smooth={true} offset={0} duration={500} delay={100}>
                        02
                    </Link>
                </li>
                <li>
                    <Link activeClass="floating-nav__item-active" to="whoWeAre" spy={true} smooth={true} offset={0} duration={500} delay={100}>
                        03
                    </Link>
                </li>
                <li>
                    <Link activeClass="floating-nav__item-active" to="reviews" spy={true} smooth={true} offset={0} duration={500} delay={100}>
                        04
                    </Link>
                </li>
                <li>
                    <Link activeClass="floating-nav__item-active" to="blog" spy={true} smooth={true} offset={0} duration={500} delay={100}>
                        05
                    </Link>
                </li>
            </ul>
        </section>
    )
}

export default FloatingNav