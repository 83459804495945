import React from 'react';

function Footer() {
    return(
        <footer className="content-block footer">
            <div className="clxs-1 offset--md-1-left content-block">
                <a href="https://www.facebook.com/ArtfulBits/" target="_blank" rel="noopener noreferrer">
                    <img className="social-link" src="/images/facebook-icon.svg" alt="facebook logo" />
                </a>
            </div>
            <div className="clxs-1 content-block">
                <a href="https://www.linkedin.com/company/artfulbits/" target="_blank" rel="noopener noreferrer">
                    <img className="social-link" src="/images/linkedin-icon.svg" alt="linkedin logo" />
                </a>
            </div>
        </footer>
    )
}

export default Footer
