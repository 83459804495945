import React from 'react';
import { Link } from 'react-router-dom';

function SectionBlog(props) {
    if (props.currentLink)
        console.log(props.currentLink);
    return(
        <section className={props.center ? 
                "content-block clmd-2 cllg-4 blog my-auto" : 
                "content-block clmd-2 cllg-4 blog"}>
            <div className="cl-full">
                <h2>
                    {props.title} 
                    <Link to={`/blog`} className={props.title === "Blog" ? "link-red" : "d-none"}>read all</Link>
                </h2>
            </div>
            {props.items.map((item,index) =>
                <Link to={`/blog/${item.link}`} className="clxs-2 clmd-3 cllg-6 blog__item" key={index}>
                    <div className="blog__item_img">
                        <img src={item.image} alt={item.alt}/>
                    </div>
                    <div className="blog__item_content">
                        <h3 className="blog__item_title">
                            {item.title}
                        </h3>
                        <p className="text blog__item_text">
                            {item.descript}
                        </p>
                        <div className="clxs-1 cllg-2 blog__item_btn-area offset--xs-1-left">
                            <button className="btn btn--read-more">read more</button>                 
                        </div>
                    </div>
                </Link>
            )}
        </section>
    )
}

export default SectionBlog