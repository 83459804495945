import React from 'react';


function PortfolioItem(props) {
    console.log(props);

    const tags = props.tags;
    const tagsItems = tags.map((item) => 
        <span className="tag-wrapper">
            <span>#</span>
            <span>{item}</span>
        </span>
    )

    const appstoreSrc = '/images/appstore.svg';
    const googleplaySrc = '/images/googleplay.svg';

    const info = props.info;
    const infoItems = info.map((item) => 
        <div key={item.title} className="content-block cl-full">
            <div className="clxs-1 clmd-1 cllg-2 ">
                <h4 className="list_item text">{item.title}:</h4>
            </div>
            <div className="clxs-1 clmd-3 cllg-4">
                <p className=" parg_item text">{item.descr}</p>
            </div>
        </div>
    )

    const website = props.website; 
    let goToWebsite;
    const appstore = props.appstore; 
    let appStore;
    const googleplay = props.googleplay; 
    let googlePlay;

    if (website && website.length) {
        goToWebsite = 
            <div className="clxs-2 clmd-1 cllg-2">
                <a className="btn btn--read-more" href={website} target="blank">go to website</a> 
            </div>
    }

    if (appstore && appstore.length) {
        appStore = 
            <div className="clxs-2 clmd-1 cllg-2">
                <a className="btn" href={appstore} target="blank">
                    <img src={appstoreSrc} className="img-fluid" alt="appstore"/>
                </a> 
            </div>
    }

    if (googleplay && googleplay.length) {
        googlePlay = 
            <div className="clxs-2 clmd-1 cllg-2">
                <a className="btn" href={googleplay} target="blank">
                    <img src={googleplaySrc} className="img-fluid" alt="googleplay"/>
                </a> 
            </div>
    }

    const blocks = props.blocks;
    const blocksItems = blocks.map((item) => {

        const blockImages = item.images_row.map((el) => 
            <div key={el}>
                <img className='img-fluid' src={el} alt={props.title}/>
            </div>
        )

        let blockColors;
        let colors;
        if (item.colors && item.colors.length) {
            colors = item.colors.map((el) => 
                <div key={el}>
                    <div className="color-item" style={{backgroundColor:el}}></div>
                    <p className="text-muted font-weight-bold">{el}</p>
                </div>
            )
            blockColors = 
                <div className='clxs-2 cllg-3'>
                    <p className="lead text-muted text-uppercase mb-1">Color palette</p>
                    <div className="colors-wrapper">
                        {colors}
                    </div>
                </div>
        }

        let blockFonts;
        let fonts;
        if (item.fonts && item.fonts.length) {
            fonts = item.fonts.map((el) => 
                <div key={el.descr}>
                    <h3 className="font-weight-bold">{el.title}</h3>
                    <img className='img-fluid' src={el.image} alt={el.title}/>
                </div>
            )
            blockFonts = 
                <div className='clxs-2 cllg-3'>
                    <p className="lead text-muted text-uppercase mb-1">Font</p>
                    <div className="fonts-wrapper">
                        {fonts}
                    </div>
                </div>
        }

        return (
            <div key={item.title} className=''>
                <h3 className='section_header'>{item.title}</h3>
                <p className='text'>{item.descr}</p>
                <div className="content-block">
                    {blockColors}
                    {blockFonts}
                </div>
                <div className='section_wraper'>
                    {blockImages}               
                </div>
            </div>
        )
    })

    const feedback = props.feedback;
    let feedbackBlock;
    if (feedback) {
        feedbackBlock = 
            <div>
                <div className='content-block'>
                    <h3 className="section_header">Customer feedback</h3>
                </div>
                <div className="content-block">
                    {/* <div className="cllg-1"></div> */}
                    <div className="clxs-2 clmd-1 cllg-1">
                        <img className="fedback_img" src={feedback.image} alt="customer"/>
                    </div>
                    <div className="clxs-2 clmd-3 cllg-5">
                        <img src="/images/portfolio/customer_feedback_quote.png" alt="quote"/>
                        <p className="text">{feedback.text}</p>
                        <h4 className="text_author">{feedback.name}</h4>
                    </div>  
                </div>
            </div>
    }

    return(
        <section>
            <div className="content-block clxs-2 clmd-4 cllg-6">
                <div className="content-block clxs-2 clmd-4 cllg-3">
                    <h2>{props.title}</h2>

                    <div className="mb-2">
                        {tagsItems}
                    </div>

                    {infoItems}
                    
                    <div className="content-block cl-full link-wrapper" >
                        {appStore}  
                        {googlePlay}
                        {goToWebsite}   
                    </div>

                </div>
                <div className="clxs-2 clmd-4 cllg-3">
                    <img className="portfolio_img" src={props.main_image} alt="portfolio"/>
                </div>
            </div>


            <div className='content-block my-auto clxs-2 clmd-4 cllg-4'>
                {blocksItems}
            </div>  

            <div className='content-block my-auto clxs-2 clmd-4 cllg-4'>
                {feedbackBlock}
            </div>  
            
            
        </section> 
    )
}

export default PortfolioItem