import React, { Component }  from 'react';
import Background from './Background'

class PrivacyPolicyMsg extends Component {
    state = {
        afbPrivacyAccept: localStorage.getItem('afbPrivacyAccept') ? true : false
    }
    
    acceptClick = (e) => {
        e.preventDefault();
        localStorage.setItem('afbPrivacyAccept', true);
        this.setState({afbPrivacyAccept: true});
    };

    render (){
        const afbPrivacyAccept = this.state.afbPrivacyAccept;
        if (afbPrivacyAccept) {
            return null;
        }

        return(
            <div className="fixed-bottom text-center wrapper-background loaded d-flex">
                <div className="relative w-100 h-100 px-2">
                    <Background/>
                    <p>This website uses cookies to ensure the best possible use of our services. If you continue to use the website, you agree to the use of cookies.</p>
                    <button className="btn btn--primary btn--small mb-2"
                        onClick={this.acceptClick}>
                            accept
                    </button>
                </div>
            </div>
        )
    }
}

export default PrivacyPolicyMsg
