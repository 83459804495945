import React, { Component } from 'react';
import 'swiper/dist/css/swiper.css';
import Swiper from 'swiper/dist/js/swiper.js';
import { Link } from 'react-scroll'

class Title extends Component {
    componentDidMount(){
        const titleSwiper = new Swiper('.swiper-container', {
            direction: 'horizontal',
            loop: true,
            simulateTouch: false,
            allowTouchMove: false,
            autoplay: {
                delay: 5000,
            }
        });
        window.addEventListener("resize", () => {
            titleSwiper.update();
        })
    }

    render(){
        return(
        <h1 className="cl-full">We are<br></br>Developers of<br className="title__br"></br>
            <span className="swiper-area">
                <div className="swiper-container">
                    <div className="swiper-wrapper">
                        <div className="swiper-slide">Web</div>
                        <div className="swiper-slide">Mobile App</div>
                        <div className="swiper-slide">UI/UX</div>
                    </div>
                </div>
            </span>
        </h1>
        );
    }
}

function SectionHome() {
    return(
        <section className="content-block home" id="home">
            <div className="clxs-2 clmd-2 cllg-3 content-block">
                <Title/>
                <Link className="clxs-2 clmd-2 cllg-2" to="weDo" smooth={true} offset={0} duration={500} delay={100}>
                    <button className="btn btn--primary cl-full">explore</button>
                </Link>
            </div>
        </section>
    )
}

export default SectionHome