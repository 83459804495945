import React from 'react';

function Background(props) {
    return(
        <section className={"lines-background"}>
            <i className="line line-padding"></i>
            <i className="line line-1"></i>
            <i className="line line-2"></i>
            <i className="line line-3"></i>
            <i className="line line-4"></i>
            <i className="line line-5"></i>
            <i className="line line-6"></i>
            <i className="line line-padding"></i>
        </section>
    )
}

export default Background