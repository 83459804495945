import React from 'react';

function SectionTitle(props) {
    if (props.title === "Blog")
        return(
            <section className="content-block section-title section-title-smaller">
                <h1 className="cl-full cllg-3">{props.title}</h1>
            </section>
        )
    else
        return(
            <section className="content-block section-title">
                <h1 className="cl-full cllg-3">{props.title}</h1>
            </section>
        )
}

export default SectionTitle