import React, { Component } from 'react';

import Head from '../../commons/Head'
import Header from '../../commons/Header'
import SectionTitle from '../../commons/SectionTitle'
import SectionTechDirections from '../../commons/SectionTechDirections'
import SectionListBlock from '../../commons/SectionListBlock'
import SectionRelated from '../../commons/SectionRelated'
import FooterContact from '../../commons/FooterContact'
import Footer from '../../commons/Footer'
import FloatingGetInTouch from '../../commons/FloatingGetInTouch'
import PrivacyPolicyMsg from '../../commons/PrivacyPolicyMsg'

class SoftwareDevelopment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            metaData: null
        };
        this.host = "https://www.artfulbits.com:3000";
        this.hostGlobal = "https://www.artfulbits.com/";
        this.socialShare = `${this.hostGlobal}images/social_share.png`;
    }
    
    componentDidMount() {
        this.setState({
            isLoaded: true
        });
        this.getData();
    }

    getData() {
        this.setState({ 
            metaData: {
                title: 'Software development integrated solutions (Custom Software Solutions) | Artfulbits Software Company',
                description: 'ArtfulBits provides custom software development services for startups and established businesses to help achieving their business goals.',
                image: this.socialShare
            },
            PageTitle: 'Software Development',
            TechContent: [
                {
                    title: 'Mobile Development',
                    descript: 'There are many types of mobile devices: phones, tablets, and wearables. Also there are numbers of different platforms: iOS, Android, Windows, and Blackberry. Each device and platform have their own specifics. We worked with all of them before, therefore, we know best practices of making beautiful and functional apps for any specific platform or cross-platform apps.',
                    imgUrl: '/images/software-development/mobile-development.svg',
                    techList: [
                        {imgUrl: '/images/tech-logos/Swift.svg',
                        alt: "Swift"},
                        {imgUrl: '/images/tech-logos/objective-c.svg',
                        alt: "Objective-C"},
                        {imgUrl: '/images/tech-logos/Kotlin-logo.svg',
                        alt: "Kotlin"},
                        {imgUrl: '/images/tech-logos/Java.svg',
                        alt: "Java"},
                        {imgUrl: '/images/tech-logos/React.svg',
                        alt: "React Native"},
                        {imgUrl: '/images/tech-logos/flutterio.svg',
                        alt: "Flutter"}
                    ]
                },
                {
                    title: 'Web Development',
                    descript: 'We create websites and dynamic web applications for start-ups and small business. We know how important for them to have professional-looking and working website that will promote engagement with their customers. We understand how important is to be live 24/7, and how vital is to be accessible from different browsers, on mobile and desktop devices.',
                    imgUrl: '/images/software-development/web-development.svg',
                    techList: [
                        {imgUrl: '/images/tech-logos/nodejs.svg',
                        alt: "Node.js"},
                        {imgUrl: '/images/tech-logos/php-logo.svg',
                        alt: "PHP"},
                        {imgUrl: '/images/tech-logos/HTML5.svg',
                        alt: "HTML5"},
                        {imgUrl: 'images/tech-logos/CSS3.svg',
                        alt: "CSS3"},
                        {imgUrl: '/images/tech-logos/JavaScript.svg',
                        alt: "JavaScript"},
                        {imgUrl: '/images/tech-logos/jquery-icon.svg',
                        alt: "jQuery"},
                        {imgUrl: '/images/tech-logos/angular.svg',
                        alt: "Angular"},
                        {imgUrl: '/images/tech-logos/React.svg',
                        alt: "React"}
                    ]
                },
                {
                    title: 'Desktop Development',
                    descript: 'Desktops are devices, where everyday production work is happening. Business performance is very much dependent on work that is being done on desktops. That’s why desktop applications need to be fast, reliable, and productive. Creation of desktop applications for Windows is something that our team is good at. From the very beginning, our company worked with Microsoft technologies, and we are recognized as Microsoft Certified partner.',
                    imgUrl: '/images/software-development/desktop-development.svg',
                    techList: [
                        {imgUrl: '/images/tech-logos/NET_Core_Logo.svg',
                        alt: ".NET Core"},
                        {imgUrl: '/images/tech-logos/Microsoft_.NET_Logo.svg',
                        alt: ".NET Framework"},
                        {imgUrl: '/images/tech-logos/csharp.svg',
                        alt: "C#/WinForms/WPF"},
                        {imgUrl: '/images/tech-logos/Visual_Studio_2017_Logo.svg',
                        alt: "MS Visual Studio 2017"},
                        {imgUrl: '/images/tech-logos/visual-studio.svg',
                        alt: "MS Visual Studio 2019"},
                        {imgUrl: '/images/tech-logos/microsoft-sql-server.svg',
                        alt: "MS SQL Server"}
                    ]
                }
            ]
        });
    }
    
    render() {
        if (this.state.TechContent)
            return(
                <React.Fragment>                 
                    <Head metaData={this.state.metaData}/>
                    <Header/>
                    <SectionTitle title={this.state.PageTitle}/>
                    <SectionTechDirections items={this.state.TechContent}/>
                    <SectionListBlock/>
                    <SectionRelated currentLink="/software-development"/>
                    <FooterContact host={this.host} />
                    <Footer/>
                    <FloatingGetInTouch/>
                    <PrivacyPolicyMsg/>
                </React.Fragment>
            )
        else 
            return (
                <div className="preload-screen">
                    <img className="logo" src="/images/logo.svg" alt="artfulbits-logo"/>
                </div>
            );
    }
}

export default SoftwareDevelopment;