import React from 'react';
import {Helmet} from 'react-helmet'
import PropTypes from "prop-types";

// function Head(props) {
//     return(
//         <Helmet>
//             <title>{props.metaData.title}</title>
//             <meta name="description" content={props.metaData.description}/>
//             <meta property="og:type" content="article"/>
//             <meta property="og:title" content={props.metaData.title}/>
//             <meta property="og:description" content={props.metaData.description}/>
//             <meta property="og:image" content={props.metaData.image}/>
//         </Helmet>
//         // <Helmet titleTemplate={`%s | ${props.metaData.title}`} />
//         // <Helmet 
//         //     titleTemplate={`props.metaData.title | %s`}
//         //     meta={[
//         //         {property: "og:title", content: "qwerty"}
//         //     ] }
//         // />
//     );
// }
const Head = props => {
    const data = props;
    // console.log(data)
  
    const postTitle = data ? data.metaData.title : ""; //(data.edges[0].node || {}).title : "";
    const postDescription = "";// data ? (data.edges[0].node || {}).subTitle : "";
    const postCover = "";// data ? (data.edges[0].node || {}).cover : "";
    // const postSlug = "";// data ? (data.edges[0].node || {}).slug : "";
  
    const title = postTitle !== "" ? `${postTitle}`  : "";
    const description = postDescription !== "" ? postDescription : "";
    const image = postCover !== "" ? postCover.resolutions.src : "";
    const url = "https://www.artfulbits.com";//config.siteUrl + config.pathPrefix + "/" + postSlug;
//   console.log(title);

    return (
      <Helmet>
        {/* General tags */}
        <title>{title}</title>
        <meta name="description" content={description} />
        {/* OpenGraph tags */}
        <meta name="og:url" content={url} />
        <meta name="og:title" content={title} />
        <meta name="og:description" content={description} />
        <meta name="og:image" content={image} />
        <meta name="og:type" content="website" />
        <meta name="fb:app_id" content="2509427422434129" />
        {/* Twitter Card tags */}
      </Helmet>
    );
  };
  
  Head.propTypes = {
    data: PropTypes.object
  };

export default Head;
