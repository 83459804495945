import React, { Component } from 'react';

import Head from '../../commons/Head'
import Header from '../../commons/Header'
import SectionTitle from '../../commons/SectionTitle'
import SectionTechDirections from '../../commons/SectionTechDirections'
import SectionListBlock from '../../commons/SectionListBlock'
import SectionRelated from '../../commons/SectionRelated'
import FooterContact from '../../commons/FooterContact'
import Footer from '../../commons/Footer'
import FloatingGetInTouch from '../../commons/FloatingGetInTouch'
import PrivacyPolicyMsg from '../../commons/PrivacyPolicyMsg'

class Design extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.host = process.env.REACT_APP_BACKEND_HOST;
        this.hostGlobal = "https://www.artfulbits.com/";
        this.socialShare = `${this.hostGlobal}images/social_share.png`;
    }
    
    componentDidMount() {
        this.setState({
            isLoaded: true
        });
        this.getData();
    }

    getData() {
        this.setState({ 
            metaData: {
                title: 'Design | Artfulbits Software Company',
                description: 'ArtfulBits provides custom software development services for startups and established businesses to help achieving their business goals.',
                image: this.socialShare
            },
            PageTitle: 'Design',
            TechContent: [
                {
                    title: 'UI/UX Design',
                    descript: 'UI is something that user sees and UX is something that user experiences. In great products these two aspects are seamlessly connected within same design concept. Only professional software UI/UX designers can create such a synergy. They know how to access and analyse user behaviour to make the product intuitive. On the other hand, they are familiar with technologies that are used in development, which allows them creating design that will be convenient in implementation for development team and will save their time.',
                    imgUrl: '/images/design/uiux.svg',
                    techList: [
                        {imgUrl: '/images/tech-logos/adobe-illustrator-cc.svg',
                        alt: "Adobe Illustrator"},
                        {imgUrl: '/images/tech-logos/photoshop-cc.svg',
                        alt: "Adobe Photoshop"},
                        {imgUrl: '/images/tech-logos/after-effects-cc.svg',
                        alt: "Adobe After Effects"},
                        {imgUrl: '/images/tech-logos/invision.svg',
                        alt: "Invision"},
                        {imgUrl: '/images/tech-logos/figma-1.svg',
                        alt: "Figma"},
                        {imgUrl: '/images/tech-logos/zeplin.svg',
                        alt: "Zeplin"},
                        {imgUrl: '/images/tech-logos/sketch.svg',
                        alt: "Sketch"}
                    ]
                },
                {
                    title: 'Business Analysis',
                    descript: 'Software products are made to improve effectiveness of business processes and to solve certain problems. Very often people and organizations are not even aware that some of their everyday routine can be automated or improved in some way. That’s where our specialist can be handy. Being in this field for many years, we created different software solutions for all types of businesses. By analyzing your business processes, we can suggest how they can be leveraged by technologies.',
                    imgUrl: '/images/design/business-analysis.svg',
                    techList: [
                        {imgUrl: '',
                        alt: ""}
                    ]
                },
                {
                    title: 'Consulting',
                    descript: 'Besides creating beautiful and functional software solutions we like to use our knowledge and experience to consult and advice our customers. Not all of them know which technology will work best for their situation. Sometimes it’s hard for them to prepare requirement specifications or access feasibility of some idea. There are situations when customer work with another team but has a need for second opinion from outside. In all such situations and in many others we can help!',
                    imgUrl: '/images/design/consulting.svg',
                    techList: [
                        {imgUrl: '',
                        alt: ""}
                    ]
                }
            ]
        });
    }

    render() {
        if (this.state.TechContent)
            return(
                <React.Fragment>
                    <Head metaData={this.state.metaData}/>
                    <Header/>
                    <SectionTitle title={this.state.PageTitle}/>
                    <SectionTechDirections items={this.state.TechContent}/>
                    <SectionListBlock/>
                    <SectionRelated currentLink="/design"/>
                    <FooterContact host={this.host} />
                    <Footer/>
                    <FloatingGetInTouch/>
                    <PrivacyPolicyMsg/>
                </React.Fragment>
            )
        else 
            return (
                <div className="preload-screen">
                    <img className="logo" src="/images/logo.svg" alt="artfulbits-logo"/>
                </div>
            );
    }
}

export default Design;