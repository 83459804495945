import React, { Component } from 'react';

import Head from '../../commons/Head'
import Header from '../../commons/Header'
import SectionBlog from '../../commons/SectionBlog'
import SectionArticle from '../../commons/SectionArticle'
import Footer from '../../commons/Footer'
import FloatingGetInTouch from '../../commons/FloatingGetInTouch'
import PrivacyPolicyMsg from '../../commons/PrivacyPolicyMsg'
import FooterContact from '../../commons/FooterContact'

class Article extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ArticleObj: null,
            metaData: null,
            related: null
        };
        this.host = process.env.REACT_APP_BACKEND_HOST;
        this.hostGlobal = "https://www.artfulbits.com/";
        this.socialShare = `${this.hostGlobal}images/social_share.png`;
    }
    
    componentDidMount() {
        this.setState({
            isLoaded: true
        });
        this.getData();
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps !== this.props) {
            this.getData();
        }
    }

    getData() {
        /**** Get Article's Data: ****/
        fetch(`../../blog-data/items/${this.props.match.params.link}.json`, {
            headers : { 
                'Content-Type': 'application/json',
                'Accept': 'application/json'
               }
        })
        .then(response => response.json())
        .then(json =>{
            this.setState({ 
                ArticleObj: json, 
                metaData: {
                    title: json.title + ' | Artfulbits Software Company',
                    description: json.descript ? json.descript : json.title,
                    image: json.image ? `${this.hostGlobal}${json.image}` : this.socialShare
                }, 
            });

            /**** Get Related Articles: ****/
            fetch(`../../blog-data/blog.json`, {
                headers : { 
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })
            .then(response => response.json())
            .then(json =>{
                this.setState({ 
                    related: json.items.filter(item => this.state.ArticleObj.relatedPosts.includes(item.link))
                });
            });

        });
            
    }
    render() {
        if (this.state.related)
            return(
                <React.Fragment>
                    <Head metaData={this.state.metaData}/>
                    <Header/>
                    <SectionArticle title={this.state.ArticleObj.title} 
                                    content={this.state.ArticleObj.content}
                                    blocks={this.state.ArticleObj.blocks}/>
                    <SectionBlog  title={BlogObj.title} items={this.state.related} center={BlogObj.center} />
                    <FooterContact host={this.host} />
                    <Footer/>
                    <FloatingGetInTouch/>
                    <PrivacyPolicyMsg/>
                </React.Fragment>
            )
        else 
            return (
                <div className="preload-screen">
                    <img className="logo" src="../images/logo.svg" alt="artfulbits-logo"/>
                </div>
            );
    }
}

// const PageTitle = 'Blog';

const BlogObj = {
    title: 'Read also',
    center: true
};

export default Article;