import React, { Component } from 'react';

import Head from '../../commons/Head'
import Header from '../../commons/Header'
import SectionTitle from '../../commons/SectionTitle'
import SectionTechDirections from '../../commons/SectionTechDirections'
import SectionListBlock from '../../commons/SectionListBlock'
import SectionRelated from '../../commons/SectionRelated'
import FooterContact from '../../commons/FooterContact'
import Footer from '../../commons/Footer'
import FloatingGetInTouch from '../../commons/FloatingGetInTouch'
import PrivacyPolicyMsg from '../../commons/PrivacyPolicyMsg'

class QualityAssurance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            metaData: null
        };
        this.host = "https://www.artfulbits.com:3000";
        this.hostGlobal = "https://www.artfulbits.com/";
        this.socialShare = `${this.hostGlobal}images/social_share.png`;
    }
    
    componentDidMount() {
        this.setState({
            isLoaded: true
        });
        this.getData();
    }

    getData() {
        this.setState({ 
            metaData: {
                title: 'Quality Assurance | Artfulbits Software Company',
                description: 'ArtfulBits provides custom software development services for startups and established businesses to help achieving their business goals.',
                image: this.socialShare
            },
            PageTitle: 'Quality Assurance',
            TechContent: [
                {
                    title: 'Functional Testing',
                    descript: 'Functional testing is a process of examination if ready-to-use applications can meet all specifications and functional requirements. Main goal is to make sure that a system can do what it is supposed to do, while being convenient in use and intuitive for end-user. It can be accomplished by verifying each piece of the application against functional requirements, gathered from stakeholders. We recommend functional testing activities to be included into any kind of project, regardless size and complexity, to ensure all deliverables will work as expected.',
                    imgUrl: '/images/quality-assurance/qa-consulting.svg',
                    techList: [
                        {imgUrl: '',
                        alt: ""}
                    ]
                },
                {
                    title: 'Performance Testing',
                    descript: 'Performance testing determines the possibility of the software program to perform efficiently under different conditions and possible scenarios. It is aimed to examine and improve the speed, responsiveness, and stability of the system to meet best practices and specific criterias. As a result of performance testing, it becomes clear which areas of system need to be improved to make it sustainable with business goals.',
                    imgUrl: '/images/quality-assurance/performance-testing.svg',
                    techList: [
                        {imgUrl: '',
                        alt: ""}
                    ]
                },
                {
                    title: 'Test Automation',
                    descript: 'Test automatization allows checking system performance, functionality, and usability via program methods. Such approach minimizes “human factor” mistakes and promotes QA efficiency by automating routine repetitive tasks. This allows quality control to be built into a software product. Generally, it makes sense to implement automated tasks for projects with longer duration which consist of many production cycles',
                    imgUrl: 'images/quality-assurance/test-automation.svg',
                    techList: [
                        {imgUrl: '/images/tech-logos/Java.svg',
                        alt: "Java"},
                        {imgUrl: '/images/tech-logos/icons8-selenium.svg',
                        alt: "Selenium"},
                        {imgUrl: '/images/tech-logos/TestNG.svg',
                        alt: "TestNG"},
                        {imgUrl: '/images/tech-logos/JUnit.svg',
                        alt: "JUnit"},
                        {imgUrl: '/images/tech-logos/allure.svg',
                        alt: "Allure"},
                        {imgUrl: '/images/tech-logos/Jenkins.svg',
                        alt: "Jenkins"},
                        {imgUrl: '/images/tech-logos/maven.svg',
                        alt: "Maven"}
                    ]
                }  
            ]
        });
    }

    render() {
        if (this.state.TechContent)
            return(
                <React.Fragment>
                    <Head metaData={this.state.metaData}/>
                    <Header/>
                    <SectionTitle title={this.state.PageTitle}/>
                    <SectionTechDirections items={this.state.TechContent}/>
                    <SectionListBlock/>
                    <SectionRelated currentLink="/quality-assurance"/>
                    <FooterContact host={this.host} />
                    <Footer/>
                    <FloatingGetInTouch/>
                    <PrivacyPolicyMsg/>
                </React.Fragment>
            )
        else 
            return (
                <div className="preload-screen">
                    <img className="logo" src="/images/logo.svg" alt="artfulbits-logo"/>
                </div>
            );
    }
}

export default QualityAssurance;