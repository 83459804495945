import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';

class SectionContact extends Component {
    state = {
        content: {
            title: 'Contact',
            items: [
                {
                    location: 'Ukraine, Lviv',
                    phone: '+38 (032) 242 07 86',
                    email: 'office@artfulbits.com',
                    active: true
                },
                {
                    location: 'Germany, Munich',
                    phone: '+49 (0) 89 381 56 3200',
                    email: 'info@artfulbits.de',
                    active: false
                }
            ]
        }
    };

    renderMarker(map, maps) {
        let marker = new maps.Marker({
            position: {
                lat: 49.827597,
                lng: 24.049406
            },
            map
        });
        console.log(marker);
    }

    render(){
        const mapProps = {
            center: {
                lat: 49.827597,
                lng: 24.049406
            },
            zoom: 15
        };

        const { content } = this.state;

        return(
            <section className="content-block clxs-2 clmd-4 cllg-2 contact">
                <div className="cl-full">
                    <h2>{content.title}</h2>
                </div>
                {content.items.map((item) =>
                    <div className="clxs-2 clmd-2 cllg-4 clxl-3 contact__item" key={item.location.split(" ").join("-") + '-' + Math.floor(Math.random() * (1000 - 9999))}>
                        <h4 className={"contact__item_location " + (item.active ? 'contact__item_location--active' : '')}>{item.location}</h4>
                        <div className="contact__item_details">
                            <a href={"tel:" + item.phone}>{item.phone}</a>
                        </div>
                        <div className="contact__item_details">
                            <a href={"mailto:" + item.email}>{item.email}</a>
                        </div>
                    </div>
                )}
                <div className="contact__map">
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: 'AIzaSyAZwf8CynnTX-dr-2cu0zKwM7Zl052k0eI' }}
                        defaultCenter={mapProps.center}
                        defaultZoom={mapProps.zoom}
                        yesIWantToUseGoogleMapApiInternals
                        onGoogleApiLoaded={({map, maps}) => this.renderMarker(map, maps)}
                    />
                </div>
            </section>
        )
    }
}

export default SectionContact