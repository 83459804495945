import React from 'react';
import { Link } from 'react-router-dom'

function SectionRelated(props) {
    const Related = {
        title: 'See Also',
        items: [
            {
                name: 'Software Development',
                link: '/software-development'
            },
            {
                name: 'Design',
                link: '/design'
            },
            {
                name: 'Quality Assurance',
                link: '/quality-assurance'
            }
        ]
    };

    const items = Related.items.filter( item => item.link !== props.currentLink );

    return(
        <section className="content-block related-block">
            <div className="cl-full">
                <h2>{Related.title}</h2>
            </div>
            {items.map((item, index) =>
                <div className="clxs-2 clmd-2 cllg-3 related-block__item" key={index}>
                    <Link to={item.link}>
                        <div className="related-block__item_content">
                            <h4>{item.name}</h4>
                        </div>
                    </Link>
                </div>
            )}
        </section>
    )
}

export default SectionRelated;
