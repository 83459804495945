import React, { Component } from 'react';

import Head from '../../commons/Head'
import Header from '../../commons/Header'
import SectionTitle from '../../commons/SectionTitle'
import SectionBlog from '../../commons/SectionBlog'
import FooterContact from '../../commons/FooterContact'
import Footer from '../../commons/Footer'
import FloatingGetInTouch from '../../commons/FloatingGetInTouch'
import PrivacyPolicyMsg from '../../commons/PrivacyPolicyMsg'
// import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
// import Article from '../../containers/article';

// const routes = [
//     {
//         title: 'Article',
//         path: '/blog/:link',
//         component: Article
//         // exact: true
//     }
// ]

class Blog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            BlogObj: null,
            metaData: null
        };
        this.host = process.env.REACT_APP_BACKEND_HOST;
        this.hostGlobal = "https://www.artfulbits.com/";
        this.socialShare = `${this.hostGlobal}images/social_share.png`;
    }
    
    componentDidMount() {
        this.setState({
            isLoaded: true
        });
        this.getData();
    }

    getData() {
        fetch(`../../blog-data/blog.json`, {
            headers : { 
                'Content-Type': 'application/json',
                'Accept': 'application/json'
               }
        })
        .then(response => response.json())
        .then(json =>{
            this.setState({ 
                BlogObj: json, 
                metaData: {
                    title: json.title + ' | Artfulbits Software Company',
                    description: json.title,
                    image: this.socialShare
                } 
            });
            
        });
    }
    
    render() {
        if (this.state.BlogObj)
            return(
                <React.Fragment>
                    
                    {/* {routes.map((route, i) => (
                        <Route key={i} {...route} />
                    ))} */}
                   
                    <Head metaData={this.state.metaData}/>
                    <Header/>
                    <SectionTitle title={this.state.BlogObj.title}/>
                    <SectionBlog  items={this.state.BlogObj.items} center={this.state.BlogObj.center} />
                    <FooterContact host={this.host} />
                    <Footer/>
                    <FloatingGetInTouch/>
                    <PrivacyPolicyMsg/>
                </React.Fragment>
            )
        else 
            return (
                <div className="preload-screen">
                    <img className="logo" src="../images/logo.svg" alt="artfulbits-logo"/>
                </div>
            );
    }
}

export default Blog;