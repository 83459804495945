import React from 'react';
import { Link } from 'react-router-dom';

function NoMatch() {
    return(
        <section className="content-block p404">
            <div className="cl-full">
                <h1 className="p404__title">404</h1>
                <h2 className="p404__subtitle">Oops, it looks like you are lost - the page doesn`t exist.</h2>
                <Link to="/"><button className="btn btn--primary cllg-2">Home page</button></Link>
            </div>
        </section>
    )
}

export default NoMatch;