import React from 'react';
import 'react-dynamic-swiper/lib/styles.css'
import { Link } from 'react-router-dom';

function PortfolioRelated(props) {
   
    console.log(props.items);
    return(
        <section className="related-section">
            <div className="cl-full">
                <h2>View also
                    <a className="link-red" href="/portfolio">view all</a>
                </h2>
            </div>
            <div className="content-block clxs-2 clmd-4 cllg-6">
                {props.items.map((item,index) =>
                    <div className="clxs-2 clmd-3 cllg-2 related-wrapper">
                        <Link className="related_link" to={`/portfolio/${item.link}`} key={index}>
                            <div className="related_img_wrapper">
                                <img className="related_img" src={item.image} alt={item.title} />
                            </div>
                            <h3 className="related_header">{item.title}</h3>
                            <h4 className="related_subheader">{item.subtitle}</h4>
                        </Link>
                    </div>
                 )} 
            </div>   
        </section> 
    )
}

export default PortfolioRelated