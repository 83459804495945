import React from 'react';

function SectionReviews(props) {
    return(<section className="content-block reviews" id="reviews">
                <div className="cl-full">
                    <h2>{props.title}</h2>
                </div>
                {props.items.map((item,index) =>
                    <div className="clxs-2 clmd-4 cllg-6 content-block reviews__item" key={index}>
                        <div className="reviews__item_photo clmd-1 cllg-1">
                            <img src="images/client.png" alt={item.author}/>
                        </div>
                        <div className="reviews__item_content clmd-3 cllg-3">
                            <p className="quote">“</p>
                            <p className="text reviews__item_text">
                                {item.text}
                            </p>
                            <p className="text review__item_author">
                                {item.author}
                            </p>
                        </div>
                    </div>
                )}
            </section>
            )
    
}

export default SectionReviews