import React, { Component } from 'react';

import Head from '../../commons/Head'
import Header from '../../commons/Header'
import PortfolioItem from '../../commons/PortfolioItem'
import FooterContact from '../../commons/FooterContact'
import Footer from '../../commons/Footer'
import FloatingGetInTouch from '../../commons/FloatingGetInTouch'
import PrivacyPolicyMsg from '../../commons/PrivacyPolicyMsg'
import PortfolioRelated from '../../commons/PortfolioRelated'

class PortfolioArticle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            PortfolioItemObj: null,
            metaData: null,
            related: null
        };
        this.host = "https://www.artfulbits.com:3000";
        this.hostGlobal = "https://www.artfulbits.com/";
        this.socialShare = `${this.hostGlobal}images/social_share.png`;
    }
    
    componentDidMount() {
        this.setState({
            isLoaded: true
        });
        this.getData();
    }
    
    componentDidUpdate(prevProps, prevState) {
        if (prevProps !== this.props) {
            this.getData();
        }
    }

    getData() {

        /**** Get Portfolio Item's Data: ****/
        fetch(`../../portfolio-data/items/${this.props.match.params.link}.json`, {
            headers : { 
                'Content-Type': 'application/json',
                'Accept': 'application/json'
               }
        })
        .then(response => response.json())
        .then(json =>{
            this.setState({ 
                PortfolioItemObj: json, 
                metaData: {
                    title: json.title + ' | Artfulbits Software Company',
                    description: json.descript ? json.descript : json.title,
                    image: json.image ? `${this.hostGlobal}${json.image}` : this.socialShare
                }, 
            }); 

             /**** Get Related Items: ****/
            fetch(`../../portfolio-data/portfolio.json`, {
                headers : { 
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })
            .then(response => response.json())
            .then(json => {
                console.log(json)
                this.setState({ 
                    related: json.items.filter(item => this.state.PortfolioItemObj.relatedPosts.includes(item.link))
                });
            });

        });   

    }
    
    render() {
        if (this.state.related)
            return(
                <React.Fragment>
                    <Head metaData={this.state.metaData}/>
                    <Header/>
                    <PortfolioItem  title={this.state.PortfolioItemObj.title} 
                                    main_image={this.state.PortfolioItemObj.main_image}
                                    tags={this.state.PortfolioItemObj.tags}
                                    info={this.state.PortfolioItemObj.info}
                                    website={this.state.PortfolioItemObj.website}
                                    appstore={this.state.PortfolioItemObj.appstore}
                                    googleplay={this.state.PortfolioItemObj.googleplay}
                                    blocks={this.state.PortfolioItemObj.blocks}
                                    feedback={this.state.PortfolioItemObj.feedback}/>
                    <PortfolioRelated items={this.state.related} />               
                    <FooterContact host={this.host} />
                    <Footer/>
                    <FloatingGetInTouch/>
                    <PrivacyPolicyMsg/>
                </React.Fragment>
            )
        else 
            return (
                <div className="preload-screen">
                    <img className="logo" src="../images/logo.svg" alt="artfulbits-logo"/>
                </div>
            );
    }
}

export default PortfolioArticle;