import React from 'react';

function SectionTechDirections(props) {
    return(
        <section className="tech-directions">
            {props.items.map((item,index) =>
                <div className="short-overview content-block" key={index}>
                    <div className="cl-full cllg-3">
                        <h2>{item.title}</h2>
                            <div className="short-overview__tech">
                                {item.techList.map((logo,index) => 
                                    <img key={index}
                                        src={logo.imgUrl} 
                                        alt={logo.alt} 
                                        title={logo.alt} />
                                )}
                                
                            </div>

                        <p className="text short-overview__description">{item.descript}</p>
                        { item.link && <button className="btn btn--primary cllg-2">read more</button> }
                    </div>
                    <div className="cl-full cllg-3">
                        <img className="short-overview__img" src={item.imgUrl} alt={item.title} />
                    </div>
                </div>
            )}
        </section>
    )
}

export default SectionTechDirections