import React from 'react';
import { Link } from 'react-scroll'

function FloatingGetInTouch() {
    return (
        <section className="floating-nav-btn">
            <ul className="text">
                <li>
                    <Link activeClass="floating-nav__item-active" to="contact" spy={true} smooth={true} offset={0} duration={500} delay={100}>
                        Get in Touch
                    </Link>
                </li>
            </ul>
        </section>
    )
}

export default FloatingGetInTouch