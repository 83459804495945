import React from 'react';
import { Link } from 'react-router-dom';

function SectionWeDo(props) {
    return(
        <section className="content-block we-do" id="weDo">
            <div className="cl-full">
                <h2>{props.title}</h2>
            </div>

            <div className="cl-full content-block we-do__items">
                {props.items.map((item, index) =>
                <Link to={item.link} className="clxs-2 clmd-2 cllg-2 we-do__item" key={index}>
                    <span className="we-do__item_number">
                        {'0' + (index + 1)}
                    </span>
                    <div className="we-do__item_background">
                        <h3>{item.title}</h3>
                        <ul>
                            {item.list.map((listItem, index) =>
                                <li key={index}>{listItem}</li>
                            )}
                        </ul>
                        <div className="content-block we-do__btn-row offset--xs-1-left offset--md-2-left">
                            <button className="btn btn--read-more">read more</button>
                        </div>
                    </div>
                </Link>
                )}
            </div>
        </section>
    )
}

export default SectionWeDo