import React, { Component } from 'react'
import { Swiper, Slide } from 'react-dynamic-swiper'
import 'react-dynamic-swiper/lib/styles.css'
import { Link } from 'react-router-dom';
 
class PortfolioSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      PortfolioObj: null,
      metaData: null,
      filter: ''
    };
    this.host = process.env.REACT_APP_BACKEND_HOST;
    this.hostGlobal = "https://www.artfulbits.com/";
    this.socialShare = `${this.hostGlobal}images/social_share.png`;
  }

  componentDidMount() {
    this.setState({
        isLoaded: true
    });
    this.getData();
  }

  getData() {
    fetch(`../../portfolio-data/portfolio.json`, {
        headers : { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
           }
    })
    .then(response => response.json())
    .then(json =>{
        this.setState({ 
            PortfolioObj: json, 
            metaData: {
                title: json.title + ' | Artfulbits Software Company',
                description: json.title,
                image: this.socialShare
            }
        });
    });
  }
 
  filtered(filter) {
    return this.state.PortfolioObj.items.filter(item => item.subtitle.toLowerCase().includes(filter));
  }

  all = () => {
    this.setState({ filter: ''});
  }
  
  web = () => {
    this.setState({filter: 'web'});
  }

  mobile = () => {
    this.setState({filter: 'mobile'});
  }

  render() {
   
    if(this.state.PortfolioObj) 
    
    return (
      <div className="clmd-6 cllg-6 my-auto">
        <h1>Our Work</h1>
        <ul className="portfolio_list">
          <li className="list-item" onClick={this.all}>all</li>
          <li className="list-item" onClick={this.web}>web</li>
          <li className="list-item" onClick={this.mobile}>mobile</li>
        </ul>
         <Swiper
            swiperOptions={{
                slidesPerView: 3,
                spaceBetween: 1,
              breakpoints: {
                667: {
                  slidesPerView: 1
                },
                996: {
                  slidesPerView: 2
                }
              }
            }}
              navigation={false}
              pagination={false} 
        >
        {this.filtered(this.state.filter).map((item,i) => (
          <Slide className="swiper__slide" key={i}>
            <div className="slide_wrapper">
              <Link to={`/portfolio/${item.link}`}>
                <img src={item.image} alt={item.title} />
                <h3 className="slide_header">{item.title}</h3>
                <h4 className="slide_subheader">{item.subtitle}</h4>
                <p className="slide_parg">{item.description}</p>
              </Link>
            </div>
          </Slide>
        ))}
      </Swiper>
      </div>
    ) 
    else  
      return (
        <div className="preload-screen">
            <img className="logo" src="../images/logo.svg" alt="artfulbits-logo"/>
        </div>
      );
    }
}



export default PortfolioSlider