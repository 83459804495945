import React from 'react';

function SectionArticle(props) {
    const blocks = props.blocks;
    const blocksItems = blocks.map((item) => {
        const image = item.image; 
        let itemImage;
        
        if (image && image.length) {
            itemImage = 
                <img src={image} alt={item.title} />
        }

        const blockDescr = item.descr.map((el) => 
            <p key={el}>{el}</p>
        )

        return (
            <div key={item.title}>
                <h2>{item.title}</h2>
                {itemImage}
                {blockDescr}
            </div>
        )
    })
    return(
        <section className="content-block clmd-2 cllg-4  my-auto">

                <div className="cl-full article__item">
                    <h2 className="blog__article_title">
                        {props.title}
                    </h2>
                    <div className="blog__article_content">
                        {/* <div className="text blog__article_text" dangerouslySetInnerHTML={ {__html: props.content} }>

                        </div> */}
                        <div className="text blog__article_text">
                            {blocksItems}
                        </div>
                    </div>
                </div>

        </section>
    )
}

export default SectionArticle