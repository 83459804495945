import React, { Component } from 'react';
import Head from '../../commons/Head'
import Header from '../../commons/Header'
import PortfolioSlider from '../../commons/PortfolioSlider'
import FooterContact from '../../commons/FooterContact'
import Footer from '../../commons/Footer'
import FloatingGetInTouch from '../../commons/FloatingGetInTouch'
import PrivacyPolicyMsg from '../../commons/PrivacyPolicyMsg'


class Portfolio extends Component {
    constructor(props) {
        super(props);
        this.state = {
            PortfolioObj: null,
            metaData: null
        };
        this.host = "https://www.artfulbits.com:3000";
        this.hostGlobal = "https://www.artfulbits.com/";
        this.socialShare = `${this.hostGlobal}images/social_share.png`;
    }
    
    componentDidMount() {
        this.setState({
            isLoaded: true
        });
        this.getData();
    }

    getData() {
        fetch(`../../portfolio-data/portfolio.json`, {
            headers : { 
                'Content-Type': 'application/json',
                'Accept': 'application/json'
               }
        })
        .then(response => response.json())
        .then(json =>{
            this.setState({ 
                PortfolioObj: json, 
                metaData: {
                    title: json.title + ' | Artfulbits Software Company',
                    description: json.title,
                    image: this.socialShare
                } 
            });
            
        });
    }
    render() {
        if (this.state.PortfolioObj)
            return(
                <React.Fragment>
                    
                    {/* {routes.map((route, i) => (
                        <Route key={i} {...route} />
                    ))} */}
                   
                    <Head metaData={this.state.metaData}/>
                    <Header/>
                    <PortfolioSlider/>
                    <FooterContact host={this.host} />
                    <Footer/>
                    <FloatingGetInTouch/>
                    <PrivacyPolicyMsg/>
                </React.Fragment>
            )
        else 
            return (
                <div className="preload-screen">
                    <img className="logo" src="../images/logo.svg" alt="artfulbits-logo"/>
                </div>
            );
    }
}

export default Portfolio;