import React, { Component } from 'react';

import Head from '../../commons/Head'
import Header from '../../commons/Header'
import SectionHome from './SectionHome'
import SectionWeDo from './SectionWeDo'
import SectionListBlock from '../../commons/SectionListBlock'
import SectionWhoWeAre from './SectionWhoWeAre'
import SectionReviews from './SectionReviews'
import SectionBlog from '../../commons/SectionBlog'
import FooterContact from '../../commons/FooterContact'
import Footer from '../../commons/Footer'
import FloatingNav from './FloatingNav'
import FloatingGetInTouch from '../../commons/FloatingGetInTouch'
import PrivacyPolicyMsg from '../../commons/PrivacyPolicyMsg'

class HomePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sectionReviews: null,
            sectionBlog: null
        };
        this.host = process.env.REACT_APP_BACKEND_HOST;
        this.hostGlobal = "https://www.artfulbits.com/";
        this.socialShare = `${this.hostGlobal}images/social_share.png`;
        this.metaData = {
            title: 'Artfulbits Software Development Company',
            description: 'ArtfulBits company provides custom software development and IT outsourcing services. We specialise in web development, iOS and Android mobile applications, and SharePoint solutions and Web Parts.',
            image: this.socialShare
        };
        
        this.WeDo = {
            title: 'We Do',
            items: [
                {
                    title: 'Software Development',
                    list: [
                        'Mobile Development',
                        'Web Development',
                        'Desktop Development'
                    ],
                    link: '/software-development'
                },
                {
                    title: 'Design',
                    list: [
                        'Business Analysis',
                        'Consulting',
                        'UI / UX'
                    ],
                    link: '/design'
                },
                {
                    title: 'Quality Assurance',
                    list: [
                        'Performance Testing',
                        'Test Automation',
                        'QA Consulting and Management'
                    ],
                    link: '/quality-assurance'
                }
            ]
        };
        
        this.HowWeDo = {
            title: 'How We Do',
            items: [
                {
                    title: 'Idea',
                    descript: 'Our goal as software developers is helping our customers during all steps of development cycle, which usually starts with conceptualization of initial business idea, or in other words turning it into defined concept. This usually requires business and technical analysis. Our tech consultants will help to access feasibility, prepare project documentation, and suggest the best technology stack for project execution. The results of this work are resources that can be presented to potential investors or customers, and then to a development team.'
                },
                {
                    title: 'UI / UX Design',
                    descript: 'UI/UX designs are prototypes for the future product. They describe how the product will look and feel, and how users will interact with it. Creating such a prototype requires not only creativity and paint skills (artfulness), but also understanding of business processes, awareness about best industry practices and specific to platform guidelines, as well as technical knowledge. Professionally made UI/UX design will reduce efforts needed for development and improve predictability of final results.'
                },
                {
                    title: 'Development',
                    descript: 'Once designs are ready and all the specs are defined, we can move on to the actual development stage. There can be different approaches and methodologies here, but for most of the cases we prefer and highly recommend iterative approach, with splitting the full project into smaller iterations - milestones. After each milestone customer, will be able to see and try a piece of a functional and tested program. Such approach allows delivering maximum business value fast and helps controlling risks.'
                },
                {
                    title: 'Release / Deployment',
                    descript: 'After any software product was created it does not bring value until it became available for end-users. For different types of software there are different ways of providing such availability: mobile apps get published to app stores, websites are being hosted on a server, and desktop applications are delivered via executable files. Nowadays releases are not a single time event, but rather a constant process which needs to be properly configured. And we know how to do it.'
                }
                ,
                {
                    title: 'Support',
                    descript: 'Technologies and environments tend to change rapidly. That’s why software products require additional attention after release to keep up with new realities. We can offer custom support plans which will allows your organization to stay flexible and prepared to unexpected situations.'
                }
            ]
        };
        
        this.Reviews = {
            title: 'Customers Say'
        };
        
        this.Blog = {
            title: 'Blog',
            center: false,
        };
    }
    
    componentDidMount() {
        this.setState({
            isLoaded: true
        });
        this.getData();
    }

    getData() {
        fetch(this.host+"/api/customerssay/get/3")
            .then(response => response.json())
                .then(json =>{
                   this.setState({ sectionReviews: json });
                   
                });
        fetch("../../blog-data/bloghome.json")
            .then(response => response.json())
                .then(json =>{
                    console.log(json);
                    this.setState({ sectionBlog: json });
                    
                });
    }
    render() {
            return(
                <React.Fragment>
                    <Head metaData={this.metaData}/>
                    <Header mainPage={true}/>
                    <SectionHome/>
                    <SectionWeDo title={this.WeDo.title} items={this.WeDo.items} />
                    <SectionListBlock title={this.HowWeDo.title} items={this.HowWeDo.items} />
                    <SectionWhoWeAre/>

                    {this.state.sectionReviews && this.state.sectionReviews.length ?
                        <SectionReviews title={this.Reviews.title} items={this.state.sectionReviews} />
                    :   null}
                    
                    {this.state.sectionBlog && this.state.sectionBlog.items && this.state.sectionBlog.items.length ?
                        <SectionBlog title={this.Blog.title} items={this.state.sectionBlog.items} />
                    :   null}

                    <FooterContact host={this.host} />
                    <Footer/>
                    <FloatingNav/>
                    <FloatingGetInTouch/>
                    <PrivacyPolicyMsg/>
                </React.Fragment>
            );
            // else 
            //     return (
            //         <div className="preload-screen">
            //             <img className="logo" src="../images/logo.svg" alt="artfulbits-logo"/>
            //         </div>
            //     );
    }
}

export default HomePage;